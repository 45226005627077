import axios from 'axios'
const service = axios.create({
    baseURL: 'http://oa.godox.net:8880/sn', // 基础路径,将统一的部分全部封装
    // baseURL: 'http://192.168.0.29:13131', // 基础路径,将统一的部分全部封装
    withCredentials: true, // 表示请求可以携带cookie
})

service.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        return config
    },
    function (error) {
        // 对请求错误做些什么
        console.log('出错了',error)
        return Promise.reject(error)
    }
)
 
// 添加响应拦截器
service.interceptors.response.use(
    function (response) {
        console.log('响应了',response)
        
        // 这个地方响应了数据该做点什么 做想做的事情
        // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response.data
        // 这个状态码是和后端约定的
        // const code = dataAxios.reset
        return dataAxios
    },
    function (error) {
        
        // 对响应错误做点什么
        console.log('出错了',error)
        // ElMessage({
        //     message: '接口报错了',
        //     type: 'success',
        //   })
        return Promise.reject(error)
    }
)

//前端采用export.default，在写后端代码时用module.export
export default service
