<template>
  <div v-if="isDD">
    <div class="page-top">
      <div class="page-title">
        <img src="./assets/WechatIMG67.png" alt="" class="page-title-logo" />
        <span class="page-title-company-name">千帆创科</span>
        <span class="page-title-name">模板中心</span>
        <!-- <span class="page-title-login">
          <el-button plain>登 录</el-button>
        </span>
        <span class="page-title-sign">
          <el-button type="primary">注 册</el-button>
        </span> -->
      </div>
      <div class="page-top-content">
        <el-row :gutter="16">
          <el-col :span="24" class="page-top-title">千帆创科方案中心</el-col>
          <el-col :span="24" class="page-top-secondary-heading">
            提供上百套多场景的官方应用，匹配您的个性化需求，实现开箱即用
          </el-col>
          <el-col :span="24" class="page-top-hot">
            <img src="./assets/hot.png" alt="" />
            <span class="page-title-company-name">热门推荐</span>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="4" class="row-bg-col">
            <el-row style="width: 100%">
              <el-col :span="10">
                <img src="./assets/crm.png" alt="" class="row-bg-col-img" />
              </el-col>
              <el-col :span="14">
                <div style="margin-top: 26px">CRM</div>
                <div style="margin-top: 5px; font-size: 14px; color: #727883">
                  销售/商机/客户
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4" class="row-bg-col">
            <el-row style="width: 100%">
              <el-col :span="10">
                <img src="./assets/jxc.png" alt="" class="row-bg-col-img" />
              </el-col>
              <el-col :span="14">
                <div style="margin-top: 26px">进销存</div>
                <div style="margin-top: 5px; font-size: 14px; color: #727883">
                  采购/销售/库存
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4" class="row-bg-col">
            <el-row style="width: 100%">
              <el-col :span="10">
                <img src="./assets/erp.png" alt="" class="row-bg-col-img" />
              </el-col>
              <el-col :span="14">
                <div style="margin-top: 26px">ERP</div>
                <div style="margin-top: 5px; font-size: 14px; color: #727883">
                  销售/采购/生产
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4" class="row-bg-col">
            <el-row style="width: 100%">
              <el-col :span="10">
                <img src="./assets/rs.png" alt="" class="row-bg-col-img" />
              </el-col>
              <el-col :span="14">
                <div style="margin-top: 26px">人事管理</div>
                <div style="margin-top: 5px; font-size: 14px; color: #727883">
                  招聘/入转调离
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4" class="row-bg-col">
            <el-row style="width: 100%">
              <el-col :span="10">
                <img src="./assets/ht.png" alt="" class="row-bg-col-img" />
              </el-col>
              <el-col :span="14">
                <div style="margin-top: 26px">合同管理</div>
                <div style="margin-top: 5px; font-size: 14px; color: #727883">
                  招聘/入转调离
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="page-top-content" style="position: relative">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="场景模板" name="first"></el-tab-pane>
        <el-tab-pane label="行业模板" name="second"></el-tab-pane>
        <el-tab-pane label="角色模板" name="third"></el-tab-pane>
        <el-tab-pane label="功能模板" name="fourth"></el-tab-pane>
      </el-tabs>
      <div style="position: absolute; right: 40px; top: 9px">
        <el-input
          placeholder="请输入内容"
          v-model="searchValue"
          class="input-with-select"
        >
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </div>
    <el-divider></el-divider>

    <div class="tab-item">
      <el-radio-group v-model="tabPosition" style="margin-bottom: 24px">
        <el-radio-button label="互联网">互联网</el-radio-button>
        <el-radio-button label="制造业">制造业</el-radio-button>
        <el-radio-button label="教育行业">教育行业</el-radio-button>
        <el-radio-button label="贸易零售">贸易零售</el-radio-button>
        <el-radio-button label="社会公益">社会公益</el-radio-button>
        <el-radio-button label="建筑地产">建筑地产</el-radio-button>
        <el-radio-button label="居民服务">居民服务</el-radio-button>
        <el-radio-button label="政府机构">政府机构</el-radio-button>
        <el-radio-button label="服务培训">服务培训</el-radio-button>
      </el-radio-group>
      <el-row :gutter="23">
        <el-col :span="6">
          <el-card class="box-card">
            <div class="box-card-img1"></div>
            <span class="img-title">进销存管理</span>
            <div class="content">
              <div class="content-title">进销存管理</div>
              <div class="content-detail">
                进销存管理系统实现企业产品数据的动态化管理，实时记录采购入库、出库...
              </div>

              <el-row class="content-foot">
                <el-col :span="18">
                  <span class="content-foot-tag">表单</span>
                </el-col>
                <el-col :span="6">44102次使用</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card">
            <div class="box-card-img2"></div>
            <span class="img-title">仓库管理</span>
            <div class="content">
              <div class="content-title">仓库管理</div>
              <div class="content-detail">
                进销存管理系统实现企业产品数据的动态化管理，实时记录采购入库、出库...
              </div>

              <el-row class="content-foot">
                <el-col :span="18">
                  <span class="content-foot-tag">表单</span>
                </el-col>
                <el-col :span="6">44102次使用</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card">
            <div class="box-card-img3"></div>
            <span class="img-title">项目管理</span>
            <div class="content">
              <div class="content-title">项目管理</div>
              <div class="content-detail">
                进销存管理系统实现企业产品数据的动态化管理，实时记录采购入库、出库...
              </div>

              <el-row class="content-foot">
                <el-col :span="18">
                  <span class="content-foot-tag">表单</span>
                </el-col>
                <el-col :span="6">44102次使用</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card">
            <div class="box-card-img4"></div>
            <span class="img-title">产品需求管理</span>
            <div class="content">
              <div class="content-title">产品需求管理</div>
              <div class="content-detail">
                进销存管理系统实现企业产品数据的动态化管理，实时记录采购入库、出库...
              </div>

              <el-row class="content-foot">
                <el-col :span="18">
                  <span class="content-foot-tag">表单</span>
                </el-col>
                <el-col :span="6">44102次使用</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="23">
        <el-col :span="6">
          <el-card class="box-card">
            <div class="box-card-img1"></div>
            <span class="img-title">进销存管理</span>
            <div class="content">
              <div class="content-title">进销存管理</div>
              <div class="content-detail">
                进销存管理系统实现企业产品数据的动态化管理，实时记录采购入库、出库...
              </div>

              <el-row class="content-foot">
                <el-col :span="18">
                  <span class="content-foot-tag">表单</span>
                </el-col>
                <el-col :span="6">44102次使用</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card">
            <div class="box-card-img2"></div>
            <span class="img-title">仓库管理</span>
            <div class="content">
              <div class="content-title">仓库管理</div>
              <div class="content-detail">
                进销存管理系统实现企业产品数据的动态化管理，实时记录采购入库、出库...
              </div>

              <el-row class="content-foot">
                <el-col :span="18">
                  <span class="content-foot-tag">表单</span>
                </el-col>
                <el-col :span="6">44102次使用</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card">
            <div class="box-card-img3"></div>
            <span class="img-title">项目管理</span>
            <div class="content">
              <div class="content-title">项目管理</div>
              <div class="content-detail">
                进销存管理系统实现企业产品数据的动态化管理，实时记录采购入库、出库...
              </div>

              <el-row class="content-foot">
                <el-col :span="18">
                  <span class="content-foot-tag">表单</span>
                </el-col>
                <el-col :span="6">44102次使用</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card">
            <div class="box-card-img4"></div>
            <span class="img-title">产品需求管理</span>
            <div class="content">
              <div class="content-title">产品需求管理</div>
              <div class="content-detail">
                进销存管理系统实现企业产品数据的动态化管理，实时记录采购入库、出库...
              </div>

              <el-row class="content-foot">
                <el-col :span="18">
                  <span class="content-foot-tag">表单</span>
                </el-col>
                <el-col :span="6">44102次使用</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="23">
        <el-col :span="6">
          <el-card class="box-card">
            <div class="box-card-img1"></div>
            <span class="img-title">进销存管理</span>
            <div class="content">
              <div class="content-title">进销存管理</div>
              <div class="content-detail">
                进销存管理系统实现企业产品数据的动态化管理，实时记录采购入库、出库...
              </div>

              <el-row class="content-foot">
                <el-col :span="18">
                  <span class="content-foot-tag">表单</span>
                </el-col>
                <el-col :span="6">44102次使用</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card">
            <div class="box-card-img2"></div>
            <span class="img-title">仓库管理</span>
            <div class="content">
              <div class="content-title">仓库管理</div>
              <div class="content-detail">
                进销存管理系统实现企业产品数据的动态化管理，实时记录采购入库、出库...
              </div>

              <el-row class="content-foot">
                <el-col :span="18">
                  <span class="content-foot-tag">表单</span>
                </el-col>
                <el-col :span="6">44102次使用</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card">
            <div class="box-card-img3"></div>
            <span class="img-title">项目管理</span>
            <div class="content">
              <div class="content-title">项目管理</div>
              <div class="content-detail">
                进销存管理系统实现企业产品数据的动态化管理，实时记录采购入库、出库...
              </div>

              <el-row class="content-foot">
                <el-col :span="18">
                  <span class="content-foot-tag">表单</span>
                </el-col>
                <el-col :span="6">44102次使用</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card">
            <div class="box-card-img4"></div>
            <span class="img-title">产品需求管理</span>
            <div class="content">
              <div class="content-title">产品需求管理</div>
              <div class="content-detail">
                进销存管理系统实现企业产品数据的动态化管理，实时记录采购入库、出库...
              </div>

              <el-row class="content-foot">
                <el-col :span="18">
                  <span class="content-foot-tag">表单</span>
                </el-col>
                <el-col :span="6">44102次使用</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <!-- <div class="page-foot">
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="3">
          <div class="page-foot-first">
            <img src="./assets/WechatIMG67.png" alt="" class="page-foot-logo" />
            <span class="page-title-company-name">千帆创科</span>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="page-foot-title">热门链接</div>
          <div class="page-foot-item">客户案例</div>
          <div class="page-foot-item">下载钉钉</div>
          <div class="page-foot-item">定价</div>
          <div class="page-foot-item">OKR</div>
          <div class="page-foot-item">管理后台</div>
          <div class="page-foot-item">OKR.com</div>
          <div class="page-foot-item">友情链接</div>
        </el-col>
        <el-col :span="3">
          <div class="page-foot-title">解决方案</div>
          <div class="page-foot-item">互联网</div>
          <div class="page-foot-item">高科技</div>
          <div class="page-foot-item">媒体</div>
          <div class="page-foot-item">人力资源</div>
          <div class="page-foot-item">销售</div>
        </el-col>
        <el-col :span="3">
          <div class="page-foot-title">帮助和支持</div>
          <div class="page-foot-item">帮助中心</div>
          <div class="page-foot-item">应用目录</div>
          <div class="page-foot-item">开放平台</div>
          <div class="page-foot-item">合作伙伴</div>
          <div class="page-foot-item">联系我们</div>
          <div class="page-foot-item">更新日志</div>
        </el-col>
        <el-col :span="3">
          <div class="page-foot-title">探索钉钉</div>
          <div class="page-foot-item">加入我们</div>
          <div class="page-foot-item">关于我们</div>
          <div class="page-foot-item">新闻中心</div>
          <div class="page-foot-item">钉钉研究社</div>
          <div class="page-foot-item">生态快讯</div>
        </el-col>
        <el-col :span="3">
          <div class="page-foot-title">钉钉系列产品</div>
          <div class="page-foot-item">钉钉文档</div>
          <div class="page-foot-item">钉钉多维表格</div>
          <div class="page-foot-item">钉钉闪记</div>
          <div class="page-foot-item">钉钉会议室</div>
        </el-col>
        <el-col :span="3">
          <div class="page-foot-title">法律与合规</div>
          <div class="page-foot-item">服务协议</div>
          <div class="page-foot-item">隐私政策</div>
          <div class="page-foot-item">安全与合规</div>
        </el-col>
      </el-row>
    </div> -->
  </div>
</template>

<script>
import { getFeed } from "@/api/index";
export default {
  data() {
    return {
      isDD: true,
      activeName: "first",
      searchValue: "",
      tabPosition: "互联网",
    };
  },
  created() {
    // var userAgentStr = window.navigator.userAgent;
    // if (/DingTalk/.test(userAgentStr)) {
    //   this.isDD = true
    // } else {
    //   this.isDD = false
    // }
    this.onLoad();
  },
  methods: {
    onLoad() {},
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style>
body {
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
    PingFang SC, Noto Sans, Noto Sans CJK SC, Microsoft YaHei, 微软雅黑, sans-serif; */
  margin: 0;
  padding: 0;
  background: #ffffff;
  /* width: 1920px; */
}

.page-title {
  /* font-family: Source Han Sans-Medium; */
  height: 60px;
  background: #edf9ff;
  display: flex;
  align-items: center;
}

.page-title-logo {
  width: 24px;
  height: 24px;
  margin-left: 122px;
}

.page-title-company-name {
  font-family: SourceHanSansCN-Bold;
  font-size: 16px;
  font-weight: 700;
  margin-left: 4px;
}

.page-title-name {
  margin-left: 106px;
  font-family: SourceHanSansCN-Medium;
}

.page-title-login {
  /* margin-right: 68px; */
  margin: 0 16px 0 auto;
}

.page-title-login .el-button {
  color: #477efd;
  border: 1px solid #477efd;
  font-size: 16px;
  padding: 9px 40px;
}

.page-title-sign {
  /* margin-left: 16px; */
  margin: 0 88px 0 0;
}

.page-title-sign .el-button {
  font-size: 16px;
  padding: 9px 40px;
}

.page-top {
  height: 424px;
  background-image: url(./assets/bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.page-top-content {
  width: 72%;
  margin: 0 auto;
}

.page-top-title {
  font-family: SourceHanSansCN-Bold;
  font-size: 40px;
  font-weight: 700;
  margin-top: 56px;
}

.page-top-secondary-heading {
  font-family: SourceHanSansCN-Regular;
  font-size: 16px;
  font-weight: 700;
  margin-top: 12px;
  color: #5e6d82;
}

.page-top-hot {
  margin-top: 45px;
  display: flex;
  align-items: center;
}

.row-bg {
  margin-top: 12px;
}

.row-bg-col {
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(214, 214, 214, 0.3);
}

.row-bg-col-img {
  margin: 26px 0 26px 16%;
}

.el-tabs__header .el-tabs__nav-wrap::after {
  height: 0;
}

.input-with-select .el-input__inner {
  height: 32px;
  line-height: 32px;
}

.el-tabs .el-tabs__header {
  margin: 0;
}

body .el-divider--horizontal {
  margin: 0;
}

.input-with-select .el-input-group__append,
.el-input-group__prepend {
  background-color: #477efd;
  color: #ffffff;
  border: 1px solid #477efd;
  padding: 0 9px;
}

.el-tabs__nav .el-tabs__item {
  height: 50px;
  line-height: 50px;
}

.tab-item {
  width: 72%;
  margin: 0 auto;
  margin-top: 20px;
}

.el-radio-group .el-radio-button:first-child .el-radio-button__inner {
  border-left: 0;
  border-radius: 0;
}

.el-radio-group .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0;
}

.el-radio-group .el-radio-button__inner {
  border: 0;
  padding: 8px 26px;
  font-size: 14px;
}

.el-radio-group .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #477efd;
  background: #ecf2fe;
  border-color: #ecf2fe;
  box-shadow: -1px 0 0 0 #ecf2fe;
}

.el-card .el-card__body {
  padding: 0;
}

.box-card {
  position: relative;
  margin-bottom: 22px;
}

.box-card-img1 {
  height: 106px;
  background-image: url("./assets/bg1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.box-card-img2 {
  height: 106px;
  background-image: url("./assets/bg2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.box-card-img3 {
  height: 106px;
  background-image: url("./assets/bg3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.box-card-img4 {
  height: 106px;
  background-image: url("./assets/bg4.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.img-title {
  position: absolute;
  top: 38px;
  left: 16px;
  font-family: SourceHanSansCN-Bold;
  font-weight: 700;
  color: #fff;
}

.content {
  padding: 10px 16px 16px 16px;
}

.content-title {
  font-family: SourceHanSansCN-Bold;
  font-weight: 700;
  color: #141e31;
}

.content-detail {
  font-family: SourceHanSansCN-Regular;
  font-size: 14px;
  font-weight: 400;
  color: #5e6d82;
}

.content-foot {
  font-family: SourceHanSansCN-Regular;
  font-size: 12px;
  font-weight: 400;
  color: #5e6d82;
  margin-top: 8px;
}

.content-foot-tag {
  padding: 2px 8px;
  height: 18px;
  background: #f0f1f4;
}

.page-foot {
  width: 72%;
  margin: 0 auto 60px;
}

.page-foot-logo {
  width: 24px;
  height: 24px;
  /* margin-left: 122px; */
}

.page-foot-first {
  /* height: 60px; */
  display: flex;
  align-items: center;
}

.page-foot-title {
  color: #5e6d82;
  font-size: 18px;
  margin-bottom: 16px;
}

.page-foot-item {
  color: #5e6d82;
  font-size: 14px;
  margin-bottom: 6px;
}
</style>
